import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Post from "../../API/Post";
import Form from "../Form/Form";
import Error from "../Error/Error";
import Success from "../Success/Success";
import { useEffect } from "react";
import getRequest from "../../API/getRequest";

const UpdateCampaign = () => {
    const { id } = useParams();

    const [plan, setplan] = useState("");
    const [title, settitle] = useState("");
    const [content, setcontent] = useState("");
    const [fundRaiser, setfundRaiser] = useState("");
    const [totalAmount, settotalAmount] = useState("");
    const [lastDate, setlastDate] = useState("");
    const [image, setimage] = useState("");
    const [isCampLoading, setisCampLoading] = useState(false);
    const [error, seterror] = useState(false);
    const [success, setsuccess] = useState(false);
    const [patrons, setpatrons] = useState([]);
    const [bankName, setbankName] = useState("");
    const [branchName, setbranchName] = useState("");
    const [accountNo, setaccountNo] = useState("");
    const [IFSC, setIFSC] = useState("");
    const [accountHolder, setaccountHolder] = useState("");

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_API_URI}/get-all-patrons`).then((res) => {
            if (res.status === "ok") {
                console.log(res);
                setpatrons(res.data);
            }
        });
    }, []);

    useEffect(() => {
        setisCampLoading(true);
        getRequest(`${process.env.REACT_APP_API_URI}/get-campaign/${id}`).then((res) => {
            console.log(res);
            if (res.status === "ok") {
                seterror(false);
                setplan(res.data.plan);
                settitle(res.data.title);
                setcontent(res.data.content);
                settotalAmount(res.data.totalAmount);
                setlastDate(res.data.lastDate);
                setbankName(res.data.bankAccount.bankName);
                setbranchName(res.data.bankAccount.branchName);
                setaccountNo(res.data.bankAccount.accountNo);
                setIFSC(res.data.bankAccount.IFSC);
                setaccountHolder(res.data.bankAccount.accountHolder);
                setisCampLoading(false);
            } else {
                seterror(res.msg);
            }
        });
    }, []);

    const clearForm = () => {
        settitle("");
        setcontent("");
        setfundRaiser("");
        settotalAmount("");
        setlastDate("");
        setimage("");
    };

    const handleClickAfterSuccess = () => {
        setsuccess(false);
    };

    const submitForm = (e) => {
        setisCampLoading(true);
        e.preventDefault();
        const formData = new FormData();

        formData.append("id", id);
        formData.append("plan", plan);
        formData.append("title", title);
        formData.append("content", content);
        formData.append("totalAmount", totalAmount);
        formData.append("moneyCollected", 0);
        formData.append("lastDate", lastDate);
        formData.append("image", image);
        formData.append("supporters", 0);
        formData.append(
            "bankAccount",
            JSON.stringify({
                bankName,
                branchName,
                accountHolder,
                accountNo,
                IFSC,
            })
        );
        Post(`${process.env.REACT_APP_API_URI}/update-campaign`, formData)
            .then((res) => {
                if (res.status === "ok") {
                    clearForm();
                    console.log(res);
                    setsuccess(true);
                } else {
                    setisCampLoading(false);
                    seterror(true);
                    console.log(res);
                }
            })
            .catch((err) => {
                setisCampLoading(false);
                seterror(true);
                console.log(err);
            });
    };

    if (success) {
        return (
            <>
                <Success
                    success={success}
                    message={"Campaign Updated Successfully"}
                />
                <Link to="/dashboard/new-campaign">
                    <button
                        className="button"
                        onClick={handleClickAfterSuccess}
                    >
                        Add New Campaign
                    </button>
                </Link>
            </>
        );
    }

    if (isCampLoading) {
        return <div className="loader"></div>;
    }

    return (
        <div className="create-container">
            <Error error={error} />
            <h1>Update Campaign</h1>
            <form className="create" onSubmit={(e) => submitForm(e)}>
                <div className="question-container">
                    <div className="circle">0</div>
                    <h1 className="question">Select the plan</h1>
                    <select
                        name="plan"
                        id=""
                        value={plan}
                        onChange={(e) => setplan(e.target.value)}
                    >
                        <option value="nill">Select a Plan</option>
                        <option value="basic">Basic</option>
                        <option value="premium">Premium</option>
                    </select>
                </div>
                <Form
                    n="1"
                    label="Title"
                    type="text"
                    placeholder="Title"
                    value={title}
                    setValue={settitle}
                    required={true}
                />
                <Form
                    n="2"
                    label="Content"
                    type="ckeditor"
                    placeholder="content"
                    value={content}
                    setValue={setcontent}
                />

                <Form
                    n="4"
                    label="Total Amount"
                    type="number"
                    placeholder="Total Amount"
                    value={totalAmount}
                    setValue={settotalAmount}
                    required={true}
                />
                <Form
                    n="5"
                    label="Last Date"
                    type="date"
                    placeholder="Last Date"
                    value={lastDate}
                    setValue={setlastDate}
                    required={true}
                />
                <Form
                    n="6"
                    label="Image"
                    type="image"
                    placeholder="Image"
                    setValue={setimage}
                />

                <h1 className="add-title">Bank Details</h1>
                <div className="bankdetails">
                    <Form
                        n="1"
                        label="Bank Name"
                        type="text"
                        placeholder="Bank Name"
                        value={bankName}
                        setValue={setbankName}
                        required={true}
                    />
                    <Form
                        n="2"
                        label="Branch Name"
                        type="text"
                        placeholder="Branch Name"
                        value={branchName}
                        setValue={setbranchName}
                        required={true}
                    />
                    <Form
                        n="3"
                        label="Account Number"
                        type="number"
                        placeholder="Account Number"
                        value={accountNo}
                        setValue={setaccountNo}
                        required={true}
                    />

                    <Form
                        n="4"
                        label="IFSC"
                        type="text"
                        placeholder="IFSC"
                        value={IFSC}
                        setValue={setIFSC}
                        required={true}
                    />
                    <Form
                        n="4"
                        label="Account Holder"
                        type="text"
                        placeholder="Account Holder"
                        value={accountHolder}
                        setValue={setaccountHolder}
                        required={true}
                    />
                </div>
                <input type="submit" className="btn" value="Update Campaign" />
            </form>
        </div>
    );
};

export default UpdateCampaign;
