import React from "react";
import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import Delete from "../../API/delete";

const ResultCard = ({ imageurl, id, title, category, del, success, error, isLoading }) => {

   
    console.log(isLoading);
   
    const createLink = (category, id) => {
        if (category === "parish") {
            return `/dashboard/update-parish/${id}`;
        } else if (category === "campaign") {
            return `/dashboard/update-campaign/${id}`;
        } else if (category === "patron") {
            return `/dashboard/update-patron/${id}`;
        } else if (category === "blog") {
            return `/dashboard/update-blog/${id}`;
        }
    }

    if(isLoading){
      return <div className="loader"></div>
    }

    return (

        <div className="resultCard">
            <Link to={e => createLink(category, id)}>
                <div className="result-Card">
                    <img className="result-img" src={imageurl} alt="na" />
                    <p>{title}</p>
                </div>
            </Link>
            {error ? <p style={{color: 'red'}}>something went wrong</p> : ""}
            <FaTrash className="delete" onClick={(e) => {
                del(id, category)

              }} />
        </div>
    );
};

export default ResultCard;
