import React, { useEffect } from "react";
import { useState } from "react";
import getRequest from "../../API/getRequest";
import SearchResult from "./SearchResult";
import Delete from "../../API/delete";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css' 


const SearchBar = () => {
    const [keyword, setkeyword] = useState("");
    const [parishes, setparishes] = useState("");
    const [campaigns, setcampaigns] = useState("");
    const [patrons, setpatrons] = useState("");
    const [blogs, setblogs] = useState("");
    const [error, seterror] = useState(false);
    const [success, setsuccess] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [parishDel, setparishDel] = useState(false)
    const [campDel, setcampDel] = useState(false)
    const [patronDel, setpatronDel] = useState(false)
    const [blogDel, setblogDel] = useState(false)


    const delParish = (id) => {
        setisLoading(true);
        Delete(`${process.env.REACT_APP_API_URI}/delete-parish`, { id: id })
            .then((res) => {
                if (res.status === "ok") {
                    console.log(res);
                    setparishDel(id)
                    setsuccess(id);
                    
                } else {
                    seterror(id);
                    setisLoading(false)
                    console.log(res);
                }
            })
            .catch((err) => {
                seterror(id);
                setisLoading(false)
                console.log(err);
            });
    }

    const delPatron = (id) => {
        setisLoading(true);
        Delete(`${process.env.REACT_APP_API_URI}/delete-patron`, { id: id })
            .then((res) => {
                if (res.status === "ok") {
                    console.log(res);
                    setpatronDel(id)
                    setsuccess(id);
                } else {
                    seterror(id);
                    console.log(res);
                    setisLoading(false)
                }
            })
            .catch((err) => {
                seterror(id);
                console.log(err);
                setisLoading(false)
            });
    }

    const delCampaign = (id) => {
        setisLoading(true);
        Delete(`${process.env.REACT_APP_API_URI}/delete-campaign`, { id: id })
            .then((res) => {
                if (res.status === "ok") {
                    console.log(res);
                    setcampDel(id)
                    setsuccess(id);
                } else {
                    seterror(id);
                    console.log(res);
                    setisLoading(false)
                }
            })
            .catch((err) => {
                seterror(id);
                console.log(err);
                setisLoading(false)
            });
    }

    const delBlog = (id) => {
        setisLoading(true);
        Delete(`${process.env.REACT_APP_API_URI}/delete-blog`, { id: id })
            .then((res) => {
                if (res.status === "ok") {
                    console.log(res);
                    setblogDel(id)
                    setsuccess(id);
                } else {
                    seterror(id);
                    console.log(res);
                    setisLoading(false)
                }
            })
            .catch((err) => {
                seterror(id);
                console.log(err);
                setisLoading(false)
            });
    }


    const handleDel = (id, category) => {
        let msg = ''
        let blogId = ''
        if(category === "patron"){
            const patron = patrons.find(patron => patron._id === id)
            msg = `Are you sure to delete ${patron.about.name}`
        } else if (category === "parish"){
            const parish = parishes.find(parish => parish._id === id)
            msg = `Are you sure to delete ${parish.about.churchName}`
        } else if (category === "campaign"){
            const campaign = campaigns.find(campaign => campaign._id === id)
            msg = `Are you sure to delete ${campaign.title}`
        } else if (category === "blog"){
            console.log(blogs)
            console.log(id)
            console.log("||||||||||||||||||||")
            const blog = blogs.find(blog => blog.slug === id)
            blogId = blog._id
            msg = `Are you sure to delete ${blog.title}`
        }
        confirmAlert({
            title: 'Delete',
            message: msg,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    console.log("||||||||||||");
                    if (category === "parish") {
                        delParish(id)
                        console.log(id);
                    } else if (category === "patron"){
                        delPatron(id)
                    } else if (category === "campaign"){
                        delCampaign(id)
                    } else if (category === "blog"){
                        delBlog(blogId)
                    }
                }
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
        });
        
    };

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_API_URI}/get-all-parishes`)
            .then((data) => {
                if (data.status === "ok") {
                    setparishes(data.data);
                    console.log(data);
                    setisLoading(false)
                }
            })
            .catch((err) => {
                seterror(true);
                console.log(err);
                setisLoading(false)
            });
        
    }, [parishDel]);

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_API_URI}/get-all-patrons`)
        .then((data) => {
            if (data.status === "ok") {
                setpatrons(data.data);
                console.log(data);
                setisLoading(false)
            }
        })
        .catch((err) => {
            seterror(true);
            console.log(err);
            setisLoading(false)
        });
    }, [patronDel])


    useEffect(() => {
        getRequest(`${process.env.REACT_APP_API_URI}/get-all-campaigns`)
        .then((data) => {
            if (data.status === "ok") {
                setcampaigns(data.data);
                console.log(data);
                setisLoading(false)
            }
        })
        .catch((err) => {
            seterror(true);
            console.log(err);
            setisLoading(false)
        });
    }, [campDel])

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_API_URI}/blogs`)
            .then((data) => {
                if (data.status === "ok") {
                    setblogs(data.blogs);
                    console.log(data);
                    setisLoading(false)
                }
            })
            .catch((err) => {
                seterror(true);
                console.log(err);
                setisLoading(false)
            });
        
    }, [blogDel]);

    if (!parishes || !patrons || !campaigns || !blogs) {
        return <div className="loader"></div>;
    }

    return (
        <div className="search-bar">
            <input
                autoFocus
                className="search"
                type="search"
                value={keyword}
                onChange={(e) => setkeyword(e.target.value)}
                placeholder="search.. "
            />
            <SearchResult
                del={(id, category) =>
                    handleDel(id, category)
                }
                isLoading={isLoading}
                error={error}
                success={success}
                parishes={parishes}
                patrons={patrons}
                campaigns={campaigns}
                blogs={blogs}
                keyword={keyword}
            />
        </div>
    );
};

export default SearchBar;
