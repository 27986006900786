import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Post from "../../API/Post";
import Form from "../Form/Form";
import Error from "../Error/Error";
import "../Parishes/AddParish.css";
import Success from "../Success/Success";
import { FaTrash } from "react-icons/fa";

const AddPatron = () => {
    const [image, setimage] = useState("");
    const [name, setName] = useState("");
    const [about, setabout] = useState("");
    const [DOB, setDOB] = useState("");

    const [familyMembers, setfamilyMembers] = useState([]);

    const [phoneNumber, setphoneNumber] = useState("");
    const [email, setemail] = useState("");
    const [presentAddress, setPresentAddress] = useState("");

    const [serves, setserves] = useState("");

    const [city, setcity] = useState("");
    const [pin, setpin] = useState("");
    const [state, setstate] = useState("");

    const [presentAddressCity, setpresentAddressCity] = useState("");
    const [presentAddresspin, setpresentAddresspin] = useState("");
    const [presentAddressstate, setpresentAddressstate] = useState("");

    const [permanentAddress, setPermanentAddress] = useState("");

    const [permanentAddressCity, setpermanentAddressCity] = useState("");
    const [permanentAddresspin, setpermanentAddresspin] = useState("");
    const [permanentAddressstate, setpermanentAddressstate] = useState("");

    const [campaigns, setcampaigns] = useState("");
    const [parishes, setparishes] = useState("");

    const [isLoading, setisLoading] = useState(false);
    const [error, seterror] = useState(false);
    const [success, setsuccess] = useState(false);

    const [nerchapettiId, setnerchapettiId] = useState("");

    const [isPaymentEnabled, setisPaymentEnabled] = useState(true);
    const [bankName, setbankName] = useState("");
    const [branchName, setbranchName] = useState("");
    const [accountNo, setaccountNo] = useState("");
    const [IFSC, setIFSC] = useState("");
    const [accountHolder, setaccountHolder] = useState("");

    const [vendorName, setvendorName] = useState()

    const deleteMember = (member) => {
        let filteredFamilyMembers = familyMembers.filter(
            (mem) => mem.name !== member.name
        );
        setfamilyMembers(filteredFamilyMembers);
    };

    const showMembers = (members) => {
        return members.map((member) => (
            <div className="member-list">
                <li>{member.name}</li>
                <FaTrash
                    onClick={(e) => deleteMember(member)}
                    style={{ color: "red", cursor: "pointer" }}
                />
            </div>
        ));
    };

    const handleClickAfterSuccess = () => {
        setsuccess(false);
    };

    const clearForm = () => {
        setName("");
        setabout("");
        setDOB("");
        setphoneNumber("");
        setemail("");
        setcity("");
        setstate("");
        setpin("");
        setserves("");
        setfamilyMembers([]);
        setbankName("")
        setbranchName("")
        setaccountNo("")
        setIFSC("")
        setaccountHolder("")
        setisPaymentEnabled(true)
        setvendorName('')
    };

    const AddFamily = () => {
        const [familyRelation, setfamilyRelation] = useState("");
        const [familyName, setfamilyName] = useState("");
        const [familyPhone, setfamilyPhone] = useState("");
        const [familyEmail, setfamilyEmail] = useState("");
        const [addMemberError, setaddMemberError] = useState(false);

        const addMember = () => {
            if (
                familyName === "" ||
                familyEmail === "" ||
                familyPhone === "" ||
                familyRelation === ""
            ) {
                setaddMemberError(true);
                setfamilyMembers(familyMembers);
            } else {
                setfamilyMembers((state) => [
                    ...state,
                    {
                        name: familyName,
                        relation: familyRelation,
                        phoneNumber: familyPhone,
                        email: familyEmail,
                    },
                ]);
            }
        };

        return (
            <div className="addcommitte">
                <Form
                    n="1"
                    label="Member Relation"
                    type="text"
                    placeholder="Member Relation"
                    value={familyRelation}
                    setValue={setfamilyRelation}
                />
                <Form
                    n="2"
                    label="Member Name"
                    type="text"
                    placeholder="Member Name"
                    value={familyName}
                    setValue={setfamilyName}
                />
                <Form
                    n="3"
                    label="Member Email"
                    type="email"
                    placeholder="Member Email"
                    value={familyEmail}
                    setValue={setfamilyEmail}
                />

                <Form
                    n="3"
                    label="Member Phone Number"
                    type="text"
                    placeholder="Member phone Number"
                    value={familyPhone}
                    setValue={setfamilyPhone}
                />
                {addMemberError ? (
                    <p style={{ color: "red" }}>
                        {" "}
                        Fill all the details of Member{" "}
                    </p>
                ) : (
                    ""
                )}
                <div className="btn add" onClick={addMember}>
                    Add Member
                </div>
            </div>
        );
    };

    const submitForm = (e) => {
        console.log("form submitted");
        setisLoading(true);
        e.preventDefault();
        const formData = new FormData();

        formData.append("vendorName", vendorName)
        formData.append("about", JSON.stringify({ name, about, DOB }));
        formData.append(
            "contact",
            JSON.stringify({
                phoneNumber,
                email,
                presentAddress: { city, pin, state },
                permanentAddress: { city, pin, state },
            })
        );
        formData.append("serves", serves);

        formData.append("family", JSON.stringify(familyMembers));

        formData.append("image", image);
        
        formData.append('isPaymentEnabled', isPaymentEnabled)
        if(isPaymentEnabled){
          formData.append(
              "bankAccount",
              JSON.stringify({
                  bankName,
                  branchName,
                  accountHolder,
                  accountNo,
                  IFSC,
              })
          );
        }
        Post(`${process.env.REACT_APP_API_URI}/add-patron`, formData)
            .then((res) => {
                if (res.status === "ok") {
                    console.log(res);
                    setnerchapettiId(res.nerchapettiId);
                    setisLoading(false);
                    setsuccess(true);
                    clearForm();
                } else {
                    setisLoading(false);
                    seterror(true);
                    console.log(res);
                }
            })
            .catch((err) => {
                setisLoading(false);
                seterror(true);
                console.log(err);
            });
    };

    if (success) {
        return (
            <>
                <Success
                    success={success}
                    message={"patron Created Successfully"}
                />
                <h1>Nerchapetti ID: {nerchapettiId}</h1>
                <Link to="/dashboard/new-patron">
                    <button
                        className="button"
                        onClick={handleClickAfterSuccess}
                    >
                        Add New Patron
                    </button>
                </Link>
            </>
        );
    }

    if (isLoading) {
        return <div className="loader"></div>;
    }

    return (
        <div className="create-container add-parish">
            <Error error={error} />
            <h1 className="main-title">Add Patron</h1>
            <form className="create" onSubmit={(e) => submitForm(e)}>
                <div className="about-details">
                    <Form
                        n="1"
                        label="Patron Name"
                        type="text"
                        placeholder="Patron Name"
                        value={name}
                        setValue={setName}
                        required={true}
                    />
                     <Form
                        n="1"
                        label="Vendor Name (Can only be alphabets !!)"
                        type="text"
                        placeholder="vendor name"
                        value={vendorName}
                        setValue={setvendorName}
                        required={true}
                    />
                    <Form
                        n="2"
                        label="About"
                        type="ckeditor"
                        placeholder="About"
                        value={about}
                        setValue={setabout}
                        required={true}
                    />
                    <Form
                        n="3"
                        label="DOB"
                        type="date"
                        placeholder="DOB"
                        value={DOB}
                        setValue={setDOB}
                        required={true}
                    />

                    <Form
                        n="6"
                        label="Image"
                        type="image"
                        placeholder="Image"
                        setValue={setimage}
                        required={true}
                    />
                </div>

                <h1 className="add-title">Contact Details</h1>
                <div className="bankdetails">
                    <Form
                        n="1"
                        label="Phone Number"
                        type="text"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        setValue={setphoneNumber}
                        required={true}
                    />
                    <Form
                        n="1"
                        label="Email"
                        type="email"
                        placeholder="Email"
                        value={email}
                        setValue={setemail}
                        required={true}
                    />
                    <Form
                        n="1"
                        label="City"
                        type="text"
                        placeholder="City"
                        value={city}
                        setValue={setcity}
                        required={true}
                    />
                    <Form
                        n="1"
                        label="State"
                        type="text"
                        placeholder="State"
                        value={state}
                        setValue={setstate}
                        required={true}
                    />
                    <Form
                        n="1"
                        label="Pin"
                        type="number"
                        placeholder="Pin"
                        value={pin}
                        setValue={setpin}
                        required={true}
                    />
                </div>

                <h1 className="add-title">Add Member</h1>
                <div className="committe-container">
                    <AddFamily />
                    <div className="committee-members">
                        <h1>Family Members</h1>
                        <ul>{showMembers(familyMembers)}</ul>
                    </div>
                </div>

                <h1 className="add-title">Add Serves</h1>
                <div className="about">
                    <Form
                        n="1"
                        label=""
                        type="ckeditor-serves"
                        placeholder="AddServes"
                        value={serves}
                        setValue={setserves}
                    />
                </div>

                <div>
                    <input
                        type="radio"
                        name="isPaymentEnabled"
                        value="true"
                        id="enable"
                        checked={isPaymentEnabled === true}
                        onChange={(e) => setisPaymentEnabled(true)}
                    />
                    <label htmlFor="enable">Enable Payment</label>
                    <input
                        type="radio"
                        name="isPaymentEnabled"
                        value="false"
                        id="disable"
                        checked={isPaymentEnabled === false}
                        onChange={(e) => setisPaymentEnabled(false)}
                    />
                    <label htmlFor="disable">Disable Payment</label>
                </div>
                {isPaymentEnabled && (
                    <div className="bankdetails">
                        <h1 className="add-title">Bank Details</h1>
                        <Form
                            n="1"
                            label="Bank Name"
                            type="text"
                            placeholder="Bank Name"
                            value={bankName}
                            setValue={setbankName}
                            required={true}
                        />
                        <Form
                            n="2"
                            label="Branch Name"
                            type="text"
                            placeholder="Branch Name"
                            value={branchName}
                            setValue={setbranchName}
                            required={true}
                        />
                        <Form
                            n="3"
                            label="Account Number"
                            type="number"
                            placeholder="Account Number"
                            value={accountNo}
                            setValue={setaccountNo}
                            required={true}
                        />

                        <Form
                            n="4"
                            label="IFSC"
                            type="text"
                            placeholder="IFSC"
                            value={IFSC}
                            setValue={setIFSC}
                            required={true}
                        />
                        <Form
                            n="4"
                            label="Account Holder"
                            type="text"
                            placeholder="Account Holder"
                            value={accountHolder}
                            setValue={setaccountHolder}
                            required={true}
                        />
                    </div>
                )}

                <input type="submit" className="btn" value="Create Patron" />
            </form>
        </div>
    );
};

export default AddPatron;
