import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import getRequest from "../../API/getRequest"
import "./Logs.css"
import { format } from 'date-fns'
const Logs = () => {

    const [logs, setlogs] = useState('')

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_API_URI}/get-logs`).then(res => {
            console.log(res);
            setlogs(res.data.reverse())
        })
    }, [])

    if(!logs){
        return <div className="loader"></div>
    }
    
    return (

        <div className="logs-container">
        <table>
            <tr>
                <th>Admin</th>
                <th>Activity</th>
                <th>Remark</th>
                <th>Date</th>
                <th>Time</th>
            </tr>

            {logs.map(log => (
                <tr>
                    <td>{log.Admin}</td>
                    <td className={log.Label}>{log.Label}</td>
                    <td>{log.Activity} having id {log.ActivityId} </td>
                    <td>{format(new Date(log.createdAt), "dd/MM/yyyy")}</td>
                    <td>{format(new Date(log.createdAt), "p")}</td>
                </tr>
            ))}
        </table>
            
        </div>
    )
}

export default Logs
