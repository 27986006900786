import React, { useState } from "react";
import Post from "../../API/Post";
import Form from "../Form/Form";
import Error from "../Error/Error";
import Success from "../Success/Success";

const AddAuthor = () => {
    const [name, setname] = useState("")
    const [image, setimage] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [error, seterror] = useState(false);
    const [success, setsuccess] = useState(false);


    const clearForm = () => {
        setname("")
        setimage("")
    };


    const submitForm = (e) => {
        setisLoading(true);
        e.preventDefault();
        const formData = new FormData();
       
        formData.append("image", image);
        formData.append("name", name)
        Post(`${process.env.REACT_APP_API_URI}/create-author`, formData)
            .then((res) => {
                if (res.status === "ok") {
                    clearForm();
                    console.log(res);
                    setisLoading(false);
                    setsuccess(true);
                } else {
                    setisLoading(false);
                    seterror(true);
                    console.log(res);
                }
            })
            .catch((err) => {
                setisLoading(false);
                seterror(true);
                console.log(err);
            });
    };

    if (success) {
        return (
            <>
                <Success
                    success={success}
                    message={"Author Added Successfully"}
                />
            </>
        );
    }

    if (isLoading) {
        return <div className="loader"></div>;
    }

    return (
        <div className="create-container">
            <Error error={error} />
            <h1>Add Author</h1>
            <form className="create" onSubmit={(e) => submitForm(e)}>
                <Form
                    n="1"
                    label="Name"
                    type="text"
                    placeholder="Name"
                    value={name}
                    setValue={setname}
                    required={true}
                />
                <Form
                    n="6"
                    label="Image"
                    type="image"
                    placeholder="Image"
                    setValue={setimage}
                    required={true}
                />



                <input type="submit" className="btn" value="Add Author" />
            </form>
        </div>
    );
};

export default AddAuthor;
